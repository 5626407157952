<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <!-- 供应商请款弹框 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="form.cargoOwnerName+' 请款'"
      :visible.sync="dialogVisible"
      width="90%"
      center
    >
      <el-form ref="defaultForm" :inline="true" :model="defaultForm" :rules="rules" class="demo-form-inline">
        <el-form-item label="请款总金额">
          <el-input v-model="defaultForm.requestFundsAmount" :placeholder="tip" type="number" @keyup.enter.native="blurHandle" @blur="blurHandle" />
        </el-form-item>
        <el-form-item label="待冲抵总金额">
          <el-input v-model="defaultForm.waitRequestFundsAmount" placeholder="待冲抵总金额" disabled type="number" />
        </el-form-item>
        <el-form-item label="收款账户" prop="receiveAccount">
          <el-select v-model="defaultForm.receiveAccount" placeholder="请选择" filterable :disabled="AccountList.length <=1 ">
            <el-option
              v-for="item in AccountList"
              :key="item.id"
              :label="item.cardNo"
              :value="item.cardNo"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="beforeSubmit('funds')">提交并请款</el-button>
          <el-button type="primary" @click="beforeSubmit('submit')">提交</el-button>
        </el-form-item>
      </el-form>
      <el-row>
        待冲抵
      </el-row>
      <el-form ref="offsetForm" :model="offsetForm" :rules="offsetForm.rules">
        <Table
          ref="waitRequestFunds"
          :table-data="offsetForm.tableData1"
          :show-summary="true"
          :get-summaries="getSummaries"
          :columns="vendorColumns"
          @select="handleWaitSelectionSelect"
          @selectAll="handleWaitSelectionSelectAll"
          @handleSelectionChange="handleWaitSelectionChange"
        >
          <!-- 结算类型和付款类型为  “收货付款”  待冲抵单据  支持全部勾选/部分勾选/不选  -->
          <el-table-column slot="selection" type="selection" width="70" :selectable="selectInit" />
          <el-table-column slot="invoice" label="是否开票" width="110" prop="ifInvoiceI18">
            <template slot-scope="scope">
              {{ scope.row.ifInvoiceI18 === 'null' ? '' : scope.row.ifInvoiceI18 }}
            </template>
          </el-table-column>
          <el-table-column slot="remittance" label="冲抵金额" align="center" width="160" prop="requestFundsAmount">
            <template slot-scope="scope">
              <el-input-number
                v-model="scope.row.requestFundsAmount"
                controls-position="right"
                :precision="2"
                style="width: 100%"
                :max="0"
                :min="scope.row.waitRequestFundsAmount"
                @change="handleNumberChange($event, scope.row)"
              />
            </template>
          </el-table-column>
        </Table>
      </el-form>
      <el-row class="mb-1">
        待请款
      </el-row>
      <el-form ref="vendorForm" :inline="true" :model="vendorForm" class="demo-form-inline">
        <el-form-item>
          <el-input v-model="vendorForm.sourceCode" placeholder="业务单据号" />
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="vendorForm.paymentType"
            value-key="id"
            clearable
            filterable
            placeholder="请选择付款类型"
          >
            <el-option v-for="item in _getAllCommodityDict('FIN_PAYMENT_TYPE')" :key="item.val" :label="item.label" :value="item.val" />
          </el-select>
        </el-form-item>
        <el-form-item label="账期截止日期(起)">
          <el-date-picker
            v-model="vendorForm.accountPeriodDateStart"
            type="date"
            style="width:100%;"
            :picker-options="accountPeriodDateStartOptions"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          />

        </el-form-item>
        <el-form-item label="账期截止日期(止)" :class="$i18n.locale">
          <el-date-picker
            v-model="vendorForm.accountPeriodDateEnd"
            type="date"
            style="width:100%;"
            :picker-options="accountPeriodDateEndOptions"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleQuery(1)">搜索</el-button>
          <el-button type="primary" @click="resetClick">重置</el-button>
        </el-form-item>
      </el-form>
      <el-form ref="ruleForm" :model="ruleForm" :rules="ruleForm.rules">
        <Table
          ref="multipleTable"
          :table-data="ruleForm.tableData1"
          :columns="vendorcolumns"
          :check="true"
          :reserve-selection="true"
          :show-summary="true"
          :get-summaries="getSummaries"
          :page-obj="pager"
          @select="select"
          @selectAll="selectAll"
          @handleSelectionChange="handleSelectionChange"
        >
          <el-table-column slot="remittance" label="请款金额" align="center" width="160" prop="requestFundsAmount">
            <template slot-scope="scope">
              <el-form-item
                :prop="'tableData1.' + scope.$index + '.requestFundsAmount'"
                :rules="ruleForm.rules.requestFundsAmount"
              >
                <el-input
                  v-model="scope.row.requestFundsAmount"
                  type="number"
                  @input="blurQkje(scope.row)"
                  @blur="blurZero(scope.row)"
                />

              </el-form-item>
            </template>
          </el-table-column>
        </Table>
        <div class="block">
          <!-- :page-sizes="[5,10,20]" -->
          <Paging :pager="pager" end :page-sizes="[100,200,500]" @pagination="pagerUpdate" /></div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Table from '@/components/Table'
import Paging from '@/components/Pagination'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import { requestFundsView, submit, submitRequestFunds } from '@/api/vendorPurchase-api.js'
import { getVendorAccountListByVendorIdAndCurrency } from '@/api/blurbInformation'
import { vendorColumns, vendorcolumns } from '@/constant/tablecolumns'
import { FloatSub, FloatAdd } from '@/utils/acc.js'
import { deepClone } from '@/utils'

export default {

  components: { Paging, Table },
  mixins: [commodityInfoDict],

  props: {
    value: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default: () => {}
    }

  },
  data() {
    const validaterequstFundsAmount = (rule, value, callback) => {
      const reg = /^\d+.?\d{0,2}$/
      if (value === '') {
        callback(new Error('必填'))
      } else if (!reg.test(value)) {
        callback(new Error('格式错误'))
      } else {
        callback()
      }
    }
    return {
      accountPeriodDateStartOptions: {
        disabledDate: (time) => {
          if (this.vendorForm.accountPeriodDateEnd) {
            return time.getTime() >= new Date(this.vendorForm.accountPeriodDateEnd).getTime()
          }
        }
      },
      accountPeriodDateEndOptions: {
        disabledDate: (time) => {
          if (this.vendorForm.accountPeriodDateStart) {
            return time.getTime() < new Date(this.vendorForm.accountPeriodDateStart).getTime() - 8.64e7
          }
        }
      },
      tip: '',
      defaultArr: [],
      // 区分税率的数组
      ARR: [],
      fullscreenLoading: false,
      multipleTable: [],
      waitMultipleTable: [],
      pager: {
        size: 100,
        current: 1,
        total: 0
      },
      vendorColumns,
      vendorcolumns,
      vendorForm: { },
      offsetForm: {
        rules: {},
        tableData1: []
      },
      defaultForm: {
        waitRequestFundsAmount: 0
      },
      ruleForm: {
        tableData1: [],
        rules: {
          requestFundsAmount: [{ required: true, message: '必填', trigger: 'blur' }, { validator: validaterequstFundsAmount, trigger: 'blur' }]
        }
      },
      requestFundsAmount: '',
      waitRequestFundsAmount: 0,
      AccountList: [],
      rules: {
        receiveAccount: [{ required: true, message: '必填', trigger: ['change'] }]
      }
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    'dialogVisible'(val) {
      if (val) {
        this._requestFundsView()
        this.changeAccountList(true)
      } else {
        sessionStorage.removeItem('json')
        this.$refs.multipleTable.$refs.table.clearSelection()
        this.requestFundsAmount = ''
        this.vendorForm = this.$options.data.call(this).vendorForm
        this.defaultForm = this.$options.data.call(this).defaultForm
        this.pager = this.$options.data.call(this).pager
        this.$refs.ruleForm.clearValidate()
      }
    }
  },
  created() {

  },
  mounted() {
    // this._getVendorAccountListByVendorId()
  },
  methods: {
    selectAll(val) {
      if (!val.length) {
        this.ruleForm.tableData1 = this.ruleForm.tableData1.map(item => { return { ...item, requestFundsAmount: '' } })
      }
    },
    async changeAccountList(flag) {
      if (flag) {
        const { vendorId, currency } = this.form
        const { datas } = await getVendorAccountListByVendorIdAndCurrency({ vendorId, currency })
        this.AccountList = datas
        this.$set(this.defaultForm, 'receiveAccount', datas.length > 0 ? datas[0].cardNo : '')
      }
    },
    select({ rows, row }) {
      const selected = rows.length && rows.indexOf(row) !== -1
      if (!selected) {
        // 取消
        this.$set(row, 'requestFundsAmount', 0)
        const obj = this.ARR.find(item => item.name === row.taxRatio) || []
        this.$set(obj, 'data', FloatAdd(Number(obj.data), Number(row.taxRatioRequestFundsAmount)))
        this.$set(row, 'taxRatioRequestFundsAmount', 0)
        // 更新缓存
        const arr = JSON.parse(sessionStorage.getItem('json')) || []
        if (arr.length) {
          if (arr.find(item => item.defaultKey === row.defaultKey)) {
            arr.find(item => item.defaultKey === row.defaultKey).requestFundsAmount = 0
            arr.find(item => item.defaultKey === row.defaultKey).taxRatioRequestFundsAmount = 0
            sessionStorage.setItem('json', JSON.stringify(arr))
          }
        }
      }
    },
    blurZero(row) {
      const reg = /^\d+.?\d{0,2}$/
      if (!reg.test(row.requestFundsAmount) || row.requestFundsAmount === '') {
        this.$set(row, 'requestFundsAmount', 0)
      }
      // 更新当前请款数据并更新剩余税率金额(未触发自动勾选或取消的更改)
      const obj = this.multipleTable.find(item => item.defaultKey === row.defaultKey) || []
      if (obj.defaultKey) {
        this.$set(obj, 'requestFundsAmount', row.requestFundsAmount)
      }
    },
    resetClick() {
      const { accountPeriodDateStart, accountPeriodDateEnd } = this.form
      this.vendorForm = Object.assign(this.$options.data.call(this).vendorForm, { accountPeriodDateEnd, accountPeriodDateStart })
    },
    beforeSubmit(flag) {
      this.$refs.defaultForm.validate((valid) => {
        if (valid) {
          this._submit(flag)
        } else {
          this.$message.warning('请选择收款账户')
        }
      })
    },
    _submit(flag) {
      const { cargoOwnerCode, vendorId, currency } = this.form
      // const defaultList = deepClone(this.defaultArr)
      const bugList = deepClone(this.defaultArr)
      if (this.multipleTable.length === 0) {
        this.$message.warning('请至少勾选一条待请款单')
        return
      } else if (this.multipleTable.length > 0) {
        try {
          // 判断能否提交
          let sum = 0
          let taxRatioRequestFundsAmountSum = 0
          this.multipleTable = this.multipleTable.map(item => {
            const obj = bugList.find(e => e.name === item.taxRatio) || []
            if (obj.data) {
              // console.log(item.requestFundsAmount, obj.data)
              if (FloatSub(item.requestFundsAmount, obj.data) <= 0) {
                // 完全够分
                this.$set(item, 'taxRatioRequestFundsAmount', item.requestFundsAmount)
                obj.data = FloatSub(Number(obj.data), Number(item.requestFundsAmount))
                this.$set(bugList.find(e => e.name === item.taxRatio), 'data', obj.data)
                // console.log(obj.data)
              } else {
                // console.log(obj.data)
                this.$set(item, 'taxRatioRequestFundsAmount', obj.data)
                this.$set(bugList.find(e => e.name === item.taxRatio), 'data', 0)
              }
            } else {
              this.$set(item, 'taxRatioRequestFundsAmount', 0)
            }
            taxRatioRequestFundsAmountSum = FloatAdd(taxRatioRequestFundsAmountSum, Number(item.taxRatioRequestFundsAmount))
            return { ...item }
          })

          // console.log(this.multipleTable)
          if (this.multipleTable.find(item => item.requestFundsAmount === 0)) {
            this.$message.warning('已选择数据存在【请款金额】等于0')
            return
          }
          this.ruleForm.tableData1.map(item => {
            sum = FloatAdd(sum, Number(item.waitRequestFundsAmount))
          })
          // 最后提示框的实际请款金额
          let actualRequestAmount = 0
          let requestFundsPendTotal = 0
          let requestFundsTotal = 0
          this.waitMultipleTable.map(item => { requestFundsPendTotal = FloatAdd(requestFundsPendTotal, Math.abs(item.requestFundsAmount)) })
          this.multipleTable.map(item => { requestFundsTotal = FloatAdd(requestFundsTotal, Math.abs(item.requestFundsAmount)) })
          actualRequestAmount = FloatSub(requestFundsTotal, requestFundsPendTotal)
          // 处理已抵用税率金额
          let waitOffsetAmountList = []
          waitOffsetAmountList = this.groupByValue(this.multipleTable, 'taxRatio').map(item => {
            let sum = 0
            const data = item.data.map(e => {
              sum += Number(e.taxRatioRequestFundsAmount)
              return sum
            })
            return { data: Math.abs(Number(data[data.length - 1])), name: item.name }
          }
          )
          waitOffsetAmountList = waitOffsetAmountList.map(item => {
            return { taxRatio: item.name, waitOffsetAmount: item.data }
          }) || []
          const waitOffsetList = this.waitMultipleTable.filter(v => v.requestFundsAmount)
          if (flag === 'submit') {
            this.$confirm(`待抵冲总金额 ${taxRatioRequestFundsAmountSum}, 本次请款金额 ${actualRequestAmount},  是否继续?`, '提示', {
              confirmButtonText: '确定',
              showCancelButton: false,
              type: 'warning'
            }).then(async() => {
              try {
                this.fullscreenLoading = true
                console.log(Object.assign({ waitOffsetAmountList, waitOffsetList: this.offsetForm.tableData1, waitRequestFundsList: this.multipleTable }))
                const { code, msg } = await submit({ cargoOwnerCode, vendorId, currency, receiveAccount: this.defaultForm.receiveAccount, waitOffsetAmountList, waitOffsetList, waitRequestFundsList: this.multipleTable })
                if (code === 0) {
                  this.$notify({
                    title: msg,
                    message: msg,
                    type: 'success'
                  })
                  this.$emit('input', false)
                  this.$emit('refresh')
                  this.$refs.multipleTable.$refs.table.clearSelection()
                }
              } finally {
                this.fullscreenLoading = false
              }
            })
          } else {
            this.$confirm(`待抵冲总金额 ${taxRatioRequestFundsAmountSum}, 本次请款金额 ${actualRequestAmount},  是否继续?`, '提示', {
              confirmButtonText: '确定',
              showCancelButton: false,
              type: 'warning'
            }).then(async() => {
              try {
                this.fullscreenLoading = true
                const { code, msg } = await submitRequestFunds({ cargoOwnerCode, vendorId, currency, receiveAccount: this.defaultForm.receiveAccount, waitOffsetAmountList, waitOffsetList, waitRequestFundsList: this.multipleTable })
                if (code === 0) {
                  this.$notify({
                    title: msg,
                    message: msg,
                    type: 'success'
                  })
                  this.$emit('input', false)
                  this.$emit('refresh')
                  this.$refs.multipleTable.$refs.table.clearSelection()
                }
              } finally {
                this.fullscreenLoading = false
              }
            })
          }
        } finally {
          this.fullscreenLoading = false
        }
      }
    },
    async handleQuery(flag) {
      // 点击搜索缓存当前页面数据，请求回来的新数据与缓存对比
      let json = []
      const arr = JSON.parse(sessionStorage.getItem('json')) || []
      if (arr.length) {
        json = arr.concat(this.ruleForm.tableData1)
        json = this.unique(json, 'defaultKey')
        sessionStorage.setItem('json', JSON.stringify(json))
      } else {
        sessionStorage.setItem('json', JSON.stringify(this.ruleForm.tableData1))
      }
      if (flag && flag === 1) {
        this.pager.current = 1
      }
      const { vendorId, currency, cargoOwnerCode } = this.form
      const params = Object.assign({}, { vendorId, currency, cargoOwnerCode }, this.pager, this.vendorForm)
      const { datas: { waitRequestFundsPage: { pager, records = [] }}} = await requestFundsView(params)
      this.ruleForm.tableData1 = records.map(item => { return { ...item, inputRequestFundsAmount: 0, taxRatioRequestFundsAmount: 0, requestFundsAmount: 0, defaultKey: `${item.purchaseSettlementCode}${item.sourceCode}` } })
      this.pager = pager
      // 分页判断能否在缓存中找到这些数据,有则从缓存中读取请款金额
      this.ruleForm.tableData1.map(item => {
        const { requestFundsAmount, taxRatioRequestFundsAmount } = arr.find(e => e.defaultKey === item.defaultKey) || []
        if (requestFundsAmount || requestFundsAmount === 0) {
          this.$set(item, 'requestFundsAmount', requestFundsAmount)
          this.$set(item, 'taxRatioRequestFundsAmount', taxRatioRequestFundsAmount)
        } else {
          // 需要自动分配的分页时才重新计算
          const obj = this.ARR.find(e => e.name === item.taxRatio) || []
          // 先用待请款金额
          if (obj.data) {
            if (item.waitRequestFundsAmount <= obj.data) {
              this.$set(item, 'requestFundsAmount', item.waitRequestFundsAmount)
              this.$set(item, 'taxRatioRequestFundsAmount', item.waitRequestFundsAmount)
              this.$set(item, 'inputRequestFundsAmount', 0)
              this.$set(obj, 'data', FloatSub(obj.data, item.waitRequestFundsAmount))
            } else {
              this.$set(item, 'requestFundsAmount', obj.data)
              this.$set(item, 'taxRatioRequestFundsAmount', obj.data)
              this.$set(obj, 'data', 0)
              // 待抵充不够用填的金额
              if (this.requestFundsAmount > 0) {
                const result = FloatSub(Number(item.waitRequestFundsAmount), Number(item.requestFundsAmount))
                if (Number(this.requestFundsAmount) >= Number(result)) {
                  this.$set(item, 'requestFundsAmount', item.waitRequestFundsAmount)
                  this.$set(item, 'inputRequestFundsAmount', Number(result))
                  this.requestFundsAmount = FloatSub(Number(this.requestFundsAmount), result)
                } else {
                  this.$set(item, 'requestFundsAmount', FloatAdd(Number(item.requestFundsAmount), Number(this.requestFundsAmount)))
                  this.$set(item, 'inputRequestFundsAmount', Number(this.requestFundsAmount))
                  this.requestFundsAmount = 0
                }
              }
            }
          } else {
            // 全用填的金额分配
            if (this.requestFundsAmount > 0) {
              if (Number(item.waitRequestFundsAmount) <= Number(this.requestFundsAmount)) {
                this.$set(item, 'requestFundsAmount', item.waitRequestFundsAmount)
                this.$forceUpdate()
                this.$set(item, 'inputRequestFundsAmount', item.waitRequestFundsAmount)
                this.requestFundsAmount = FloatSub(this.requestFundsAmount, item.requestFundsAmount)
              } else {
                this.$set(item, 'requestFundsAmount', this.requestFundsAmount)
                this.$set(item, 'inputRequestFundsAmount', this.requestFundsAmount)
                this.requestFundsAmount = FloatSub(this.requestFundsAmount, item.waitRequestFundsAmount)
              }
            } else {
              this.$set(item, 'requestFundsAmount', 0)
              this.$set(item, 'inputRequestFundsAmount', 0)
            }
            this.$set(item, 'taxRatioRequestFundsAmount', 0)
          }
        }
      })
      this.$nextTick(() => {
        for (let i = 0; i < this.ruleForm.tableData1.length; i++) {
          this.$refs.multipleTable.$refs.table.toggleRowSelection(
            this.ruleForm.tableData1[i],
            !!this.ruleForm.tableData1[i].requestFundsAmount
          )
        }
      })
    },
    async _requestFundsView() {
      try {
        this.fullscreenLoading = true
        const { vendorId, currency, cargoOwnerCode, accountPeriodDateStart, accountPeriodDateEnd } = this.form
        this.vendorForm = Object.assign({}, this.vendorForm, { accountPeriodDateStart, accountPeriodDateEnd })
        const params = Object.assign({}, { vendorId, currency, cargoOwnerCode, accountPeriodDateStart, accountPeriodDateEnd }, this.pager)
        const { datas: { waitOffsetList = [], waitRequestFundsPage: { pager, records = [] }, waitRequestFundsAmount = 0 }} = await requestFundsView(params)
        this.offsetForm.tableData1 = waitOffsetList.map(item => { return { ...item, requestFundsAmount: item.waitRequestFundsAmount } })
        this.ruleForm.tableData1 = records.map(item => {
          return { ...item, taxRatioRequestFundsAmount: 0, inputRequestFundsAmount: 0, requestFundsAmount: 0, defaultKey: `${item.purchaseSettlementCode}${item.sourceCode}` }
        })
        this.offsetForm.tableData1.map(item => {
          this.defaultForm.waitRequestFundsAmount = FloatAdd(this.defaultForm.waitRequestFundsAmount, Number(item.requestFundsAmount))
          return this.defaultForm.waitRequestFundsAmount
        }) || []
        this.waitRequestFundsAmount = waitRequestFundsAmount
        this.tip = FloatSub(Number(this.waitRequestFundsAmount), Number(Math.abs(this.defaultForm.waitRequestFundsAmount)))
        this.ARR = this.groupByValue(this.offsetForm.tableData1, 'taxRatio')
        this.ARR = this.ARR.map(item => {
          let sum = 0
          const data = item.data.map(e => {
            // sum += Number(e.waitRequestFundsAmount)
            sum = FloatAdd(sum, Number(e.waitRequestFundsAmount))
            return sum
          })
          return { data: Math.abs(Number(data[data.length - 1])), name: item.name }
        }
        )
        this.defaultArr = deepClone(this.ARR)
        this.ruleForm.tableData1.map(item => {
          const obj = this.ARR.find(e => e.name === item.taxRatio) || []
          // 先用待请款金额
          if (obj.data) {
            if (item.waitRequestFundsAmount <= obj.data) {
              this.$set(item, 'requestFundsAmount', item.waitRequestFundsAmount)
              this.$set(item, 'taxRatioRequestFundsAmount', item.waitRequestFundsAmount)
              this.$set(item, 'inputRequestFundsAmount', 0)
              this.$set(obj, 'data', FloatSub(obj.data, item.waitRequestFundsAmount))
            } else {
              this.$set(item, 'requestFundsAmount', obj.data)
              this.$set(item, 'taxRatioRequestFundsAmount', obj.data)
              this.$set(obj, 'data', 0)
            }
          }
        })
        this.$nextTick(() => {
          for (let i = 0; i < this.ruleForm.tableData1.length; i++) {
            this.$refs.multipleTable.$refs.table.toggleRowSelection(
              this.ruleForm.tableData1[i],
              !!this.ruleForm.tableData1[i].requestFundsAmount
            )
          }
          for (let i = 0; i < this.offsetForm.tableData1.length; i++) {
            this.$refs.waitRequestFunds.$refs.table.toggleRowSelection(this.offsetForm.tableData1[i], true)
          }
        })
        let json = []
        const arr = JSON.parse(sessionStorage.getItem('json')) || []
        if (arr.length) {
          json = arr.concat(this.ruleForm.tableData1)
          json = this.unique(json, 'defaultKey')
          sessionStorage.setItem('json', JSON.stringify(json))
        } else {
          sessionStorage.setItem('json', JSON.stringify(this.ruleForm.tableData1))
        }
        this.pager = pager
      } finally {
        this.fullscreenLoading = false
      }
    },
    getSummaries(param) {
      const { columns, data } = param || {}
      const sums = []
      const arr = ['requestFundsAmount', 'waitRequestFundsAmount', 'settlementAmount']
      columns && columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        if (arr.includes(column.property)) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            const num = Number(values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0))
            sums[index] = (num + '').indexOf('.') === -1 ? num : num.toFixed(2)
          } else {
            sums[index] = ''
          }
        }
      })
      return sums
    },
    groupByValue(arr, value) {
      const map = []
      const result = []
      for (let i = 0; i < arr.length; i++) {
        const obj = arr[i]
        if ((obj[value] || obj[value] === 0) && !map[obj[value]]) {
          result.push(
            {
              name: obj[value],
              data: [obj]
            }
          )
          map[obj[value]] = obj
        } else {
          for (let j = 0; j < result.length; j++) {
            const aj = result[j]
            if (aj.name === obj[value]) {
              aj.data.push(obj)
            }
          }
        }
      }
      return result
    },

    unique(arr, attrName) {
      const res = new Map()
      return arr.filter((a) => !res.has(a[attrName]) && res.set(a[attrName], 1))
    },
    pagerUpdate(val) {
      let json = []
      const arr = JSON.parse(sessionStorage.getItem('json')) || []
      if (arr.length) {
        json = arr.concat(this.ruleForm.tableData1) // 两个数组对象合并
        json = this.unique(json, 'defaultKey')
        sessionStorage.setItem('json', JSON.stringify(json))
      } else {
        sessionStorage.setItem('json', JSON.stringify(this.ruleForm.tableData1))
      }
      this.pager = val
      this.handleQuery(0)
    },
    handleSelectionChange(val) {
      this.multipleTable = val
    },
    handleWaitSelectionChange(val) {
      this.waitMultipleTable = val
      this.calcWaitRequestFundsAmount()
    },
    // 更改请款金额时实时存缓存
    blurQkje(row) {
      const reg = /^\d+.?\d{0,2}$/
      if (row.requestFundsAmount === 0 || !reg.test(row.requestFundsAmount)) {
        this.$set(row, 'requestFundsAmount', '')
        // 自动取消的勾选
        const obj = this.ARR.find(item => item.name === row.taxRatio) || []
        this.$set(obj, 'data', FloatAdd(Number(obj.data), Number(row.taxRatioRequestFundsAmount)))
        this.$set(row, 'taxRatioRequestFundsAmount', 0)
      }
      if (row.requestFundsAmount > row.waitRequestFundsAmount) {
        this.$set(row, 'requestFundsAmount', row.waitRequestFundsAmount)
      }
      // console.log(this.ARR)
      let outPairsBoo = true
      if (row.requestFundsAmount <= 0 || row.requestFundsAmount > row.waitRequestFundsAmount || !reg.test(row.requestFundsAmount)) outPairsBoo = false
      this.$refs.multipleTable.$refs.table.toggleRowSelection(row, outPairsBoo)
      const arr = JSON.parse(sessionStorage.getItem('json')) || []
      if (arr.length) {
        if (arr.find(item => item.defaultKey === row.defaultKey)) {
          arr.find(item => item.defaultKey === row.defaultKey).requestFundsAmount = row.requestFundsAmount
          sessionStorage.setItem('json', JSON.stringify(arr))
        }
      }
    },

    blurHandle() {
      this.$refs.multipleTable.$refs.table.clearSelection()
      // 复原this.ARR
      this.ARR = deepClone(this.defaultArr)
      // 复原两个隐藏请款参数
      this.ruleForm.tableData1 = this.ruleForm.tableData1.map(item => {
        return { ...item, taxRatioRequestFundsAmount: 0, inputRequestFundsAmount: 0 }
      })
      sessionStorage.removeItem('json')
      const { requestFundsAmount } = this.defaultForm
      this.requestFundsAmount = Number(requestFundsAmount)
      if (this.requestFundsAmount >= 0) {
        this.ruleForm.tableData1.map(item => {
          const obj = this.ARR.find(e => e.name === item.taxRatio) || []
          // 先用待请款金额
          if (obj.data) {
            if (item.waitRequestFundsAmount <= obj.data) {
              this.$set(item, 'requestFundsAmount', item.waitRequestFundsAmount)
              this.$set(item, 'taxRatioRequestFundsAmount', item.waitRequestFundsAmount)
              this.$set(item, 'inputRequestFundsAmount', 0)
              this.$set(obj, 'data', FloatSub(obj.data, item.waitRequestFundsAmount))
            } else {
              this.$set(item, 'requestFundsAmount', obj.data)
              this.$set(item, 'taxRatioRequestFundsAmount', obj.data)
              this.$set(obj, 'data', 0)
              // 待抵充不够用填的金额
              if (this.requestFundsAmount > 0) {
                const result = FloatSub(Number(item.waitRequestFundsAmount), Number(item.requestFundsAmount))
                if (Number(this.requestFundsAmount) >= Number(result)) {
                  this.$set(item, 'requestFundsAmount', item.waitRequestFundsAmount)
                  this.$set(item, 'inputRequestFundsAmount', Number(result))
                  this.requestFundsAmount = FloatSub(Number(this.requestFundsAmount), result)
                } else {
                  this.$set(item, 'requestFundsAmount', FloatAdd(Number(item.requestFundsAmount), Number(this.requestFundsAmount)))
                  this.$set(item, 'inputRequestFundsAmount', Number(this.requestFundsAmount))
                  this.requestFundsAmount = 0
                }
              }
            }
          } else {
            // 全用填的金额分配
            if (this.requestFundsAmount > 0) {
              if (Number(item.waitRequestFundsAmount) <= Number(this.requestFundsAmount)) {
                this.$set(item, 'requestFundsAmount', item.waitRequestFundsAmount)
                this.$forceUpdate()
                this.$set(item, 'inputRequestFundsAmount', item.waitRequestFundsAmount)
                this.requestFundsAmount = FloatSub(this.requestFundsAmount, item.requestFundsAmount)
              } else {
                this.$set(item, 'requestFundsAmount', this.requestFundsAmount)
                this.$set(item, 'inputRequestFundsAmount', this.requestFundsAmount)
                this.requestFundsAmount = FloatSub(this.requestFundsAmount, item.waitRequestFundsAmount)
              }
            } else {
              this.$set(item, 'requestFundsAmount', 0)
              this.$set(item, 'inputRequestFundsAmount', 0)
            }
            this.$set(item, 'taxRatioRequestFundsAmount', 0)
          }
        })
        this.$nextTick(() => {
          for (let i = 0; i < this.ruleForm.tableData1.length; i++) {
            this.$refs.multipleTable.$refs.table.toggleRowSelection(
              this.ruleForm.tableData1[i],
              !!this.ruleForm.tableData1[i].requestFundsAmount
            )
          }
        })
      }
    },
    handleNumberChange(value, row) {
      if (value === undefined || value === '') {
        this.$set(row, 'requestFundsAmount', 0)
      } else {
        this.$nextTick(() => {
          this.$refs.waitRequestFunds.$refs.table.toggleRowSelection(row, true)
        })
      }
      this.calcWaitRequestFundsAmount()
    },
    selectInit(row) {
      return row.settlementType === 3 && row.paymentType === 3
    },
    handleWaitSelectionSelect({ rows, row }) {
      const selected = rows.length && rows.indexOf(row) !== -1
      if (!selected) {
        this.$set(row, 'requestFundsAmount', 0)
      }
    },
    handleWaitSelectionSelectAll(rows) {
      if (rows.length < this.offsetForm.tableData1.length) {
        this.offsetForm.tableData1.forEach(item => {
          if (item.settlementType === 3 && item.paymentType === 3) {
            this.$set(item, 'requestFundsAmount', 0)
          }
        })
      }
    },
    calcWaitRequestFundsAmount() {
      this.defaultForm.waitRequestFundsAmount = this.waitMultipleTable.reduce((prev, current) => prev + current.requestFundsAmount || 0, 0)
    }
  }
}
</script>

<style scoped lang="scss">
/deep/.el-table .cell {
 /*    height:38px!important;
    line-height: 38px!important; */
      .el-form-item {
    margin-bottom: 0px;
}
}
</style>
