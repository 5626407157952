<template>
  <div>
    <el-form ref="form" :model="form" label-width="130px" style="display: flex" :rules="rules">
      <div class="el-lt" style="width: 85%">
        <el-row :span="24" class="row-input" :class="{ 'show-row': showRow }">
          <el-col :span="8">
            <el-form-item label="账期截止日期(起)" :class="$i18n.locale">
              <el-date-picker
                v-model="form.accountPeriodDateStart"
                type="date"
                style="width:100%;"
                :picker-options="accountPeriodDateStartOptions"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="账期截止日期(止)" :class="$i18n.locale">
              <el-date-picker
                v-model="form.accountPeriodDateEnd"
                type="date"
                style="width:100%;"
                :picker-options="accountPeriodDateEndOptions"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              />
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="来源单号" :class="$i18n.locale">
              <el-input v-model="form.sourceCode" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="结算单号" :class="$i18n.locale">
              <el-input v-model="form.purchaseSettlementCode" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="结算主体" :class="$i18n.locale" prop="cargoOwnerCode">
              <el-select v-model="form.cargoOwnerCode" style="width:100%;" value-key="id" clearable filterable>
                <el-option
                  v-for="item in cargoOwnerNameList"
                  :key="item.cargoOwnerName"
                  :label="item.cargoOwnerName"
                  :value="item.cargoOwnerCode"
                />
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="供应商" :class="$i18n.locale" prop="vendorId">
              <el-select
                v-model="form.vendorId"
                filterable
                style="width:100%;"
                clearable
                :placeholder="$t('page.selectPlaceholder')"
              >
                <el-option v-for="item in vendorOptions" :key="item.id" :label="item.vendorName" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="付款状态" :class="$i18n.locale">
              <el-select v-model="form.paymentStatus" value-key="id" clearable filterable style="width:100%;">
                <el-option
                  v-for="item in _getAllCommodityDict('FIN_PAYMENT_STATUS')"
                  :key="item.val"
                  :label="item.label"
                  :value="item.val"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="结算类型" :class="$i18n.locale">
              <el-select v-model="form.settlementType" value-key="id" clearable style="width:100%;" filterable>
                <el-option
                  v-for="item in _getAllCommodityDict('FIN_SETTLEMENT_TYPE')"
                  :key="item.val"
                  :label="item.label"
                  :value="item.val"
                />

              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="结算方式" :class="$i18n.locale">
              <el-select v-model="form.settlementMethod" value-key="id" clearable style="width:100%;" filterable>
                <el-option
                  v-for="item in _getAllCommodityDict('FIN_SETTLEMENT_METHOD')"
                  :key="item.val"
                  :label="item.label"
                  :value="item.val"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="请款审批状态" :class="$i18n.locale">
              <el-select
                v-model="form.requestFundsApproveStatus"
                value-key="id"
                clearable
                style="width:100%;"
                filterable
              >
                <el-option
                  v-for="item in _getAllCommodityDict('FIN_PS_APPROVE_STATUS')"
                  :key="item.val"
                  :label="item.label"
                  :value="item.val"
                />
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="结算状态" :class="$i18n.locale">
              <el-select v-model="form.settlementStatus" value-key="id" clearable filterable style="width:100%;">
                <el-option
                  v-for="item in _getAllCommodityDict('FIN_SETTLEMENT_STATUS')"
                  :key="item.val"
                  :label="item.label"
                  :value="item.val"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="币种" :class="$i18n.locale" prop="currency">
              <el-select v-model="form.currency" value-key="id" clearable filterable style="width:100%;">
                <el-option
                  v-for="item in _getAllCommodityDict('CURRENCY_TYPE')"
                  :key="item.val"
                  :label="item.label"
                  :value="item.val"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="请款单号" :class="$i18n.locale">
              <el-input v-model="form.requestFundsCode" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="采购单号" :class="$i18n.locale">
              <el-input v-model="form.poCode" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="是否开票" :class="$i18n.locale">
              <el-select v-model="form.ifInvoice" value-key="id" clearable style="width:100%;">
                <el-option
                  v-for="item in _getAllCommodityDict('IF_INVOICE')"
                  :key="item.val"
                  :label="item.label"
                  :value="item.val"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :span="24" class="mb-3">
          <el-col :span="24">
            <el-button v-permission="'requestFunds'" type="primary" @click="requestFunds">请款</el-button>
            <el-button type="primary" @click="collectView">收款</el-button>
          </el-col>
        </el-row>
      </div>
      <el-row class="el-rt" style="width: 25%">
        <el-col class="row-center">
          <el-button type="primary" :loading="queryLoading" @click="queryClick">{{
            $t("page.search")
          }}</el-button>
          <el-button @click="resetClick()">{{ $t("page.reset") }}</el-button>
          <el-button type="text" @click="showRow=!showRow">{{
            showRow ? $t("page.hide") : $t("page.expand")
          }}</el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-table
      ref="uploadTableDataRef"
      :data="detailData"
      :header-cell-style="{ background: '#fafafa' }"
      tooltip-effect="dark"
      max-height="600px"
      :loading="queryLoading"
      show-summary
      :summary-method="getSummaries"
      :row-class-name="isShowExpend"
    >
      <el-table-column type="expand">
        <template slot-scope="props">
          <div class="my_table">
            <el-table :data="props.row.requestFundsVOList" max-height="200px">
              <el-table-column :label="$t('page.No')" type="index" width="80" align="center" />
              <el-table-column prop="requestFundsCode" label="请款单" />
              <el-table-column prop="createTime" label="请款时间" />
              <el-table-column prop="requestFundsAmount" label="请款金额" width="130" />
              <el-table-column prop="createByName" label="请款人" />
              <el-table-column prop="requestFundsApproveStatusI18" label="请款审批状态" />
              <el-table-column prop="requestFundsApproveDate" label="请款审批时间" />
              <el-table-column prop="paymentStatusI18" label="付款状态" />
              <el-table-column prop="paymentTime" label="付款时间" />
            </el-table>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('page.No')" type="index" width="80" align="center" sortable>
        <template slot-scope="scope">
          {{ (pager.current - 1)*pager.size + scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="purchaseSettlementCode" label="结算单号" min-width="170">
        <template slot-scope="scope">
          <el-button type="text" :loading="scope.row.loading" @click="showDialog(scope.row)">
            {{ scope.row.purchaseSettlementCode }}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="sourceCode" label="来源单号" min-width="170" />
      <el-table-column prop="settlementType" label="结算类型">
        <template slot-scope="scope">
          {{ getAuditTypeLabel('FIN_SETTLEMENT_TYPE',scope.row.settlementType) }}
        </template>
      </el-table-column>
      <el-table-column prop="cargoOwnerName" label="结算主体" />
      <el-table-column prop="settlementMethod" label="结算方式">
        <template slot-scope="scope">
          {{ getAuditTypeLabel('FIN_SETTLEMENT_METHOD',scope.row.settlementMethod) }}
        </template>
      </el-table-column>
      <el-table-column prop="vendorName" label="供应商" width="120" />
      <el-table-column prop="createTime" label="生成时间" min-width="150" />
      <el-table-column prop="settlementAmount" label="结算金额" width="130" />
      <el-table-column prop="currency" label="币种">
        <template slot-scope="scope">
          {{ getAuditTypeLabel('CURRENCY_TYPE', scope.row.currency) }}
        </template>
      </el-table-column>
      <el-table-column prop="accountPeriodDate" label="账单截止日期" min-width="110" />
      <el-table-column prop="requestFundsAmount" label="已请款金额" width="130" />
      <el-table-column prop="waitRequestFundsAmount" label="待请款金额" width="130" />
      <el-table-column prop="settlementStatusI18" label="结算状态" width="100" />
      <el-table-column prop="requestFundsApproveStatusI18" label="请款审批状态" width="110" />
      <el-table-column prop="requestFundsApproveTime" label="请款审批时间" width="110" />
      <el-table-column prop="paymentStatusI18" label="付款状态" />

    </el-table>
    <div class="block">
      <Paging :pager="pager" end @pagination="pagerUpdate" />
    </div>
    <detailDialog v-model="dialogVisible" :row="row" />
    <!-- 请款弹框 -->
    <requestfundsDialog v-model="vendorDialogVisible" :form="form" @refresh="queryClick" />
    <!-- 收款弹框 -->
    <collectViewDialog v-model="collectViewVisible" :form="form" @refresh="queryClick" />
  </div>
</template>

<script>
import { columns1, columns2, columns3, columns4 } from '@/constant/tablecolumns'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import { queryVendorList, getcargoownerList } from '@/api/scm-api'
import Paging from '@/components/Pagination'
import detailDialog from '../components/purchaseDialog'
import requestfundsDialog from '../components/requestfundsDialog'
import collectViewDialog from '../components/collectViewDialog'
import { purchaseSettlement, purchaseSettlementDetail } from '@/api/vendorPurchase-api.js'

export default {
  components: { Paging, detailDialog, requestfundsDialog, collectViewDialog },
  mixins: [commodityInfoDict],
  data() {
    return {
      accountPeriodDateStartOptions: {
        disabledDate: (time) => {
          if (this.form.accountPeriodDateEnd) {
            return time.getTime() >= new Date(this.form.accountPeriodDateEnd).getTime()
          }
        }
      },
      accountPeriodDateEndOptions: {
        disabledDate: (time) => {
          if (this.form.accountPeriodDateStart) {
            return time.getTime() < new Date(this.form.accountPeriodDateStart).getTime() - 8.64e7
          }
        }
      },
      cargoOwnerNameList: [],
      vendorDialogVisible: false,
      collectViewVisible: false,
      row: {
        title: '',
        labelArr: [],
        columns1: [],
        columns2: [],
        columns3: []

      },
      dialogVisible: false,
      pager: {
        total: 0,
        current: 1,
        size: 20
      },
      detailData: [

      ],
      form: {
        vendorId: '',
        cargoOwnerCode: '',
        currency: ''
      },
      showRow: false,
      vendorOptions: [],
      queryLoading: false,
      rules: {
        vendorId: { required: true, message: '必填', trigger: 'change' },
        cargoOwnerCode: { required: true, message: '必填', trigger: 'change' },
        currency: { required: true, message: '必填', trigger: 'change' }
      }
    }
  },
  computed: {
    params() {
      this.form.cargoOwnerName ? delete this.form.cargoOwnerName : ''
      return Object.assign(this.pager, this.form)
    }
  },
  created() {

  },
  mounted() {
    this._queryVendorList()
    this._getcargoownerList()
    this.queryClick()
  },
  methods: {
    isShowExpend(row, index) {
      if (row.row.requestFundsVOList && row.row.requestFundsVOList.length === 0) {
        return 'hide-expend'
      }
      return ''
    },
    getSummaries(param) {
      const { columns, data } = param || {}
      const sums = []
      const arr = ['settlementAmount', 'requestFundsAmount', 'waitRequestFundsAmount']
      columns && columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        if (arr.includes(column.property)) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            const num = Number(values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0))
            sums[index] = (num + '').indexOf('.') === -1 ? num : num.toFixed(2)
          } else {
            sums[index] = ''
          }
        }
      })
      return sums
    },
    requestFunds() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const { cargoOwnerName } = this.cargoOwnerNameList.find(item => this.form.cargoOwnerCode === item.cargoOwnerCode)
          this.form.cargoOwnerName = cargoOwnerName
          this.vendorDialogVisible = true
        } else {
          this.$message.warning('请选择供应商，结算主体，币种')
          this.showRow = true
        }
      })
    },
    collectView() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const { cargoOwnerName } = this.cargoOwnerNameList.find(item => this.form.cargoOwnerCode === item.cargoOwnerCode)
          this.form.cargoOwnerName = cargoOwnerName
          this.collectViewVisible = true
        } else {
          this.$message.warning('请选择供应商，结算主体，币种')
          this.showRow = true
        }
      })
    },
    async _getcargoownerList() {
      const { datas } = await getcargoownerList()
      this.cargoOwnerNameList = datas
    },
    getlabelArr(a, b) {
      const labelArr = []
      a.map((item, index) => {
        b.map((e, i) => {
          if (i === index) {
            labelArr.push({ label: item, value: this.row[e] })
          }
        })
      })
      return labelArr
    },
    async showDialog(row) {
      try {
        this.$set(row, 'loading', true)
        const { datas, datas: { billTaxAmountVOList = [], outBillVOList = [], purchaseSettlementDetailVOList = [], requestFundsVOList = [] }} = await purchaseSettlementDetail(row.id)
        this.row = datas
        if (row.orderType + '' === '2') {
          const label = ['结算汇总单', '结算主体', '供应商', '结算金额', '未税总金额', '增值总税额', '结算状态', '币种']
          const value = ['purchaseSettlementCode', 'cargoOwnerName', 'vendorName', 'settlementAmount', 'noTaxAmount', 'valueAddedTaxAmount', 'settlementStatusI18', 'currency']
          this.row = Object.assign({}, {
            title: '结算汇总单', labelArr: this.getlabelArr(label, value), columns1, tableData1: outBillVOList,
            tableData2: billTaxAmountVOList.map(item => { return { ...item, includes: `涉及${item.taxRatio}税率结算金额` } }), tableData3: requestFundsVOList, columns2, columns3
          })
        } else {
          const label = ['预付应付单', '结算主体', '供应商', '付款比例%', '付款金额', '未税金额', '增值税额', '币种', '结算状态']
          const value = ['purchaseSettlementCode', 'cargoOwnerName', 'vendorName', 'paymentRatio', 'paymentAmount', 'noTaxAmount', 'valueAddedTaxAmount', 'currency', 'settlementStatusI18']
          this.row = Object.assign({}, {
            title: '预付应付单详情', labelArr: this.getlabelArr(label, value),
            columns1: columns4,
            tableData1: purchaseSettlementDetailVOList.map(item => { return { ...item, specification: item.style + '/' + item.color + '/' + item.size } }),
            tableData2: billTaxAmountVOList.map(item => { return { ...item, includes: `涉及${item.taxRatio}税率结算金额` } }), tableData3: requestFundsVOList, columns2, columns3
          })
        }
        this.dialogVisible = true
      } finally {
        this.$set(row, 'loading', false)
      }
    },
    pagerUpdate(val) {
      this.pager = val
      this.handleClick()
    },
    async _queryVendorList() {
      const { datas } = await queryVendorList({ vendorType: 4 })
      this.vendorOptions = datas
    },
    queryClick() {
      this.pager.current = 1
      this.handleClick()
    },
    async handleClick() {
      try {
        this.queryLoading = true
        const { code, datas: { records, pager }} = await purchaseSettlement(this.params)
        if (code === 0) {
          this.pager = pager
          this.detailData = records.map(item => { return { ...item, loading: false, requestFundsVOList: item.requestFundsVOList || [] } })
        }
      } finally {
        this.queryLoading = false
        this.$refs.form.clearValidate()
      }
    },
    resetClick() {
      this.form = this.$options.data.call(this).form
      this.queryClick()
    }
  }
}
</script>

<style scoped lang="scss">
/deep/.my_table {
  .el-table__row > td {
    border: none;
  }
  .el-table::before {
    height: 0px;
  }
}
/deep/ .hide-expend .el-table__expand-icon {
  display: none;
}
</style>
