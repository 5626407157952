<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <!-- 供应商收款弹框 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="form.cargoOwnerName+' 收款'"
      :visible.sync="dialogVisible"
      width="90%"
      center
    >
      <el-row :gutter="20" style="margin-bottom:20px">
        <el-col :span="4" style="display:flex;align-items:center">
          <span>收款总金额：</span>
          <el-input-number v-model="collectTotalAmount" :max="0" :controls="false" style="flex:1" @change="updateCollectAmount" />
        </el-col>
        <el-col :span="4" style="display:flex;align-items:center">
          <span>付款账户：</span>
          <el-select v-model="paymentAccount" placeholder="请选择" filterable :disabled="paymentAccountList.length <= 1" style="flex:1">
            <el-option v-for="item in paymentAccountList" :key="item.id" :label="item.cardNo" :value="item.cardNo" />
          </el-select>
        </el-col>
        <el-col :span="5">
          <el-button type="primary" @click="_submit">提交</el-button>
        </el-col>
      </el-row>
      <el-row class="mb-1">
        待收款
      </el-row>
      <Table
        ref="collectTable"
        :table-data="collectDetailViewDTOList"
        :columns="collectcolumns"
        :show-summary="true"
        :get-summaries="getSummaries"
        :check="true"
        :reserve-selection="true"
        @select="select"
        @selectAll="selectAll"
        @handleSelectionChange="handleSelectionChange"
      >
        <el-table-column slot="remittance" label="收款金额" align="center" width="160" prop="collectAmount">
          <template slot-scope="scope">
            <el-input-number
              v-model="scope.row.collectAmount"
              :controls="false"
              :precision="2"
              :min="scope.row.waitCollectAmount"
              :max="0"
              style="width:100%"
              @blur="updateTable($event, scope.$index)"
            />
          </template>
        </el-table-column>
      </Table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Table from '@/components/Table'
import { collectcolumns } from '@/constant/tablecolumns'
import { getVendorAccountListByVendorIdAndCurrency } from '@/api/blurbInformation'
import { collectView, collectSubmit } from '@/api/vendorPurchase-api'
import { FloatAdd } from '@/utils/acc.js'
import { pick } from 'xe-utils/methods'

export default {

  components: { Table },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default: () => {}
    }

  },
  data() {
    return {
      fullscreenLoading: false,
      paymentAccount: null,
      paymentAccountList: [],
      collectcolumns,
      collectDetailViewDTOList: [],
      collectTotalAmount: 0,
      multipleTable: []
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    'dialogVisible'(val) {
      if (val) {
        this._collectView()
        this._getVendorAccountListByVendorIdAndCurrency()
      } else {
        this.$refs.collectTable.$refs.table.clearSelection()
      }
    }
  },
  methods: {
    async _getVendorAccountListByVendorIdAndCurrency() {
      const { vendorId, currency } = this.form
      const { datas } = await getVendorAccountListByVendorIdAndCurrency({ vendorId, currency })
      this.paymentAccountList = datas || []
      this.paymentAccount = this.paymentAccountList.length ? this.paymentAccountList[0].cardNo : null
    },
    async _collectView() {
      const { vendorId, currency, cargoOwnerCode } = this.form
      const { datas } = await collectView({ cargoOwnerCode, currency, vendorId })
      this.collectDetailViewDTOList = datas?.collectDetailViewDTOList || []
      this.collectDetailViewDTOList.forEach(v => {
        v.collectAmount = v.waitCollectAmount
      })
      this.multipleTable = this.collectDetailViewDTOList
      this.$nextTick(() => {
        this.$refs.collectTable.$refs.table.toggleAllSelection()
      })
    },
    updateTable($event, index) {
      const target = this.collectDetailViewDTOList[index]
      this.$set(this.collectDetailViewDTOList, index, Object.assign(target, { collectAmount: $event.target.value || 0 }))
      this.collectTotalAmount = this.multipleTable.reduce((prev, current) => Number(FloatAdd(prev, current.collectAmount || 0)), 0) || 0
      this.$nextTick(() => {
        for (let i = 0; i < this.collectDetailViewDTOList.length; i++) {
          this.$refs.collectTable.$refs.table.toggleRowSelection(
            this.collectDetailViewDTOList[i], this.collectDetailViewDTOList[i].collectAmount < 0
          )
        }
      })
    },
    updateCollectAmount(value) {
      if (value === undefined || value === '') {
        this.collectTotalAmount = 0
      }
      if (this.collectTotalAmount <= 0) {
        this.collectDetailViewDTOList.map((v, index) => {
          const result = this.collectTotalAmount - v.waitCollectAmount
          this.$set(this.collectDetailViewDTOList, index, Object.assign(v, { collectAmount: result >= 0 ? this.collectTotalAmount : v.waitCollectAmount }))
          const isSelected = !!(result < 0 || this.collectTotalAmount)
          this.$nextTick(() => {
            this.$refs.collectTable.$refs.table.toggleRowSelection(v, isSelected)
          })
          this.collectTotalAmount = result >= 0 ? 0 : result
        })
      }
    },
    getSummaries(param) {
      const { columns } = param || {}
      const sums = []
      const arr = ['purchaseAmount', 'waitCollectAmount', 'invoiceAmount', 'noInvoiceAmount', 'collectAmount']
      columns && columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        if (arr.includes(column.property)) {
          const values = this.multipleTable.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            const num = Number(values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0))
            sums[index] = (num + '').indexOf('.') === -1 ? num : num.toFixed(2)
          } else {
            sums[index] = 0
          }
        }
      })
      return sums
    },
    async _submit() {
      if (!this.paymentAccount) {
        this.$message.warning('请选择付款账户')
        return
      }
      if (this.multipleTable.length === 0) {
        this.$message.warning('请至少勾选一条待收款单')
        return
      }
      if (this.collectTotalAmount === 0) {
        this.$message.warning('收款总金额为0，不能提交收款')
        return
      }
      try {
        this.fullscreenLoading = true
        const { vendorId, currency, cargoOwnerCode } = this.form
        const collectDetailSubmitRequestDTOList = this.multipleTable.filter(v => v.collectAmount).map(v => pick(v, ['purchaseSettlementCode', 'collectAmount']))
        const { code, msg } = await collectSubmit({
          vendorId,
          currency,
          cargoOwnerCode,
          collectDetailSubmitRequestDTOList,
          paymentAccount: this.paymentAccount
        })
        if (code === 0) {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
          this.$emit('input', false)
          this.$emit('refresh')
        }
      } finally {
        this.fullscreenLoading = false
      }
    },
    select({ rows, row }) {
      const selected = rows.length && rows.indexOf(row) !== -1
      if (!selected) {
        this.$set(row, 'collectAmount', 0)
      }
    },
    selectAll(val) {
      if (!val.length) {
        this.collectDetailViewDTOList = this.collectDetailViewDTOList.map(item => { return { ...item, collectAmount: 0 } })
      }
    },
    handleSelectionChange(val) {
      this.multipleTable = [...new Set(val)]
      this.collectTotalAmount = this.multipleTable.reduce((prev, current) => Number(FloatAdd(prev, current.collectAmount || 0)), 0) || 0
    }
  }
}
</script>
